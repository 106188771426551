<template>
  <v-card light flat>
    <v-toolbar color="color1 color1Text--text" v-if="toolbar">
      <v-toolbar-title>Team Importer</v-toolbar-title>
    </v-toolbar>
    <loading-bar v-model="loading"></loading-bar>
    <v-expand-transition>
      <div v-if="currentStep">
        <!-- TITLE ROW -->
        <v-card-title class="title font-weight-regular justify-space-between" ref="topOfPage">
          <span>{{ currentStep.title }}</span>
        </v-card-title>
        <!-- WINDOW STEPPER -->
        <v-card-text class="pt-0">
          <v-window v-model="window" touchless>
            <v-window-item v-for="step in steps" :key="step.value" :value="step.value">
              <!-- FILE PICKER -->
              <v-card color="grey lighten-4" v-if="step.type === 'select-file'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" class="subtitle-1">
                      {{ currentFile ? 'You are currently using:' : 'Which file would you like to upload?'}}
                    </v-col>
                    <v-expand-transition>
                      <v-col cols="12" v-if="currentFile" class="subtitle-1">
                        {{currentFile.name}}
                      </v-col>
                    </v-expand-transition>
                    <v-col cols="12">
                      <v-btn
                        raised
                        large
                        class="color3 white--text"
                        @click="onPickFile"
                        :disabled="loading"
                      >{{`Choose a ${currentFile ? 'new ' : ''}File`}}</v-btn>
                      <input
                        type="file"
                        style="display: none"
                        ref="fileInput"
                        accept=".csv"
                        @change="onFilePicked">
                  </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- VERIFY DATA -->
              <v-card color="grey lighten-4" v-if="step.type === 'verify-data'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" class="subtitle-1">
                      Double check if the column headers are correct and uncheck the box below if your file did not have headers
                    </v-col>
                    <v-col cols="12" class="title">
                      <v-checkbox
                        color="color3"
                        label="My file has column headers"
                        v-model="hasHeader"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :items="rows"
                        :headers="headers"
                        v-if="parseResuls"
                        class="elevation-1"
                        dense
                      >
                      </v-data-table>
                  </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- COLUMN MAPPING -->
              <v-card color="grey lighten-4" v-if="step.type === 'column-mapping'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" md="6" lg="4" class="subtitle-1">
                      Map the column of your .CSV file to the values needed by choosing the column
                      header from the drop down list.
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="6" lg="4">
                      <v-card>
                        <v-toolbar color="color1 color1Text--text">
                          <v-toolbar-title>Columns</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                        <template v-for="(t, i) in regFields">
                          <v-row :key="`regFields${i}`" dense>
                            <v-col cols="12">
                              <v-autocomplete
                                :items="columnOptions"
                                v-model="t.value"
                                :label="t.name"
                                :hint="`Sample Data: ${parseResuls && parseResuls.data[0] ? parseResuls.data[0][t.value] : ''}`"
                                persistent-hint
                                color="color3"
                                item-color="color3"
                                :rules="[(v) => !!v || 'Choose a column']"
                                style="white-space: nowrap; text-overflow: ellipsis;"
                                :solo-inverted="!t.value"
                                :disabled="!teamEdit && !!t.value"
                                :menu-props="{
                                  closeOnContentClick: true
                                }"
                              >
                                <template v-slot:prepend>
                                  <v-slide-x-reverse-transition
                                    mode="out-in"
                                  >
                                    <v-icon
                                      :key="`icon-${i}-${t.value ? 'success' : 'error'}`"
                                      v-text="t.value ? 'fas fa-check-circle' : 'fas fa-times-circle'"
                                      :color="t.value ? 'success' : 'error'"
                                    ></v-icon>
                                  </v-slide-x-reverse-transition>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                        </template>
                          <v-row dense>
                            <v-col class="text-end pa-0">
                              <v-btn
                                color="color3"
                                small
                                @click.stop="teamEdit = !teamEdit"
                                text
                              >
                                {{teamEdit ? 'disable' : 'enable'}} edit
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- VERIFY DIVISIONS -->
              <v-card color="grey lighten-4" v-if="step.type === 'verify-divisions'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" md="6" lg="4" class="subtitle-1">
                      Double check if the Divisions are correctly matched up
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <!-- Divisions -->
                    <v-col cols="12" md="6" lg="4">
                      <v-card>
                        <v-toolbar color="color1 color1Text--text">
                          <v-toolbar-title>Divisions</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                          <template v-for="(t, i) in mappedDivisions">
                            <v-row :key="`import${i}`" dense>
                              <v-col cols="12">
                                <v-autocomplete
                                  dense
                                  :label="t.name"
                                  :key="`team_${i}`"
                                  :items="tournament.publicDivisions"
                                  item-text="name"
                                  color="color3"
                                  item-color="color3"
                                  v-model="t.division"
                                  return-object
                                  :disabled="t.division && !divisionEdit"
                                  :solo-inverted="!t.division || divisionEdit === i"
                                  :menu-props="{
                                    closeOnContentClick: true
                                  }"
                                >
                                  <template v-slot:prepend>
                                    <v-slide-x-reverse-transition
                                      mode="out-in"
                                    >
                                      <v-icon
                                        :key="`icon-${i}-${t.division ? 'success' : 'error'}`"
                                        v-text="t.division ? 'fas fa-check-circle' : 'fas fa-times-circle'"
                                        :color="t.division ? 'success' : 'error'"
                                      ></v-icon>
                                    </v-slide-x-reverse-transition>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                          </template>
                          <v-row dense>
                            <v-col class="text-end pa-0">
                              <v-btn
                                color="color3"
                                small
                                @click.stop="divisionEdit = !divisionEdit"
                                text
                              >
                                {{divisionEdit ? 'disable' : 'enable'}} edit
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0" v-if="false">
                      <v-card>
                        <v-toolbar color="color1 color1Text--text">
                          <v-toolbar-title>Match Settings</v-toolbar-title>
                        </v-toolbar>
                        <match-settings-editor
                          v-if="matchSettings"
                          :setting="matchSettings"
                          :edit="true"
                          :notime="true"
                        ></match-settings-editor>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- VERIFY DATES -->
              <v-card color="grey lighten-4" v-if="step.type === 'verify-dates'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" md="6" lg="4" class="subtitle-1">
                      Double check if the Dates are correctly matched up
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <!-- Dates -->
                    <v-col cols="12" md="6" lg="4">
                      <v-card>
                        <v-toolbar color="color1 color1Text--text">
                          <v-toolbar-title>Dates</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                          <template v-for="(t, i) in mappedDates">
                            <v-row :key="`import${i}`" dense>
                              <v-col cols="12">
                                <v-autocomplete
                                  dense
                                  :label="`${t.division.name} - ${t.name}`"
                                  :key="`team_${i}`"
                                  :items="t.division.days"
                                  :item-text="item => item.name + ' — ' + item.date.format('MM/DD/YYY')"
                                  color="color3"
                                  item-color="color3"
                                  v-model="t.round"
                                  return-object
                                  :disabled="t.round && !datesEdit"
                                  :solo-inverted="!t.round || datesEdit === i"
                                  :menu-props="{
                                    closeOnContentClick: true
                                  }"
                                >
                                  <template v-slot:prepend>
                                    <v-slide-x-reverse-transition
                                      mode="out-in"
                                    >
                                      <v-icon
                                        :key="`icon-${i}-${t.round ? 'success' : 'error'}`"
                                        v-text="t.round ? 'fas fa-check-circle' : 'fas fa-times-circle'"
                                        :color="t.round ? 'success' : 'error'"
                                      ></v-icon>
                                    </v-slide-x-reverse-transition>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                          </template>
                          <v-row dense>
                            <v-col class="text-end pa-0">
                              <v-btn
                                color="color3"
                                small
                                @click.stop="datesEdit = !datesEdit"
                                text
                              >
                                {{datesEdit ? 'disable' : 'enable'}} edit
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0" v-if="false">
                      <v-card>
                        <v-toolbar color="color1 color1Text--text">
                          <v-toolbar-title>Match Settings</v-toolbar-title>
                        </v-toolbar>
                        <match-settings-editor
                          v-if="matchSettings"
                          :setting="matchSettings"
                          :edit="true"
                          :notime="true"
                        ></match-settings-editor>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- VERIFY SLOTS -->
              <v-card color="grey lighten-4" v-if="step.type === 'verify-slots'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" md="6" lg="4" class="subtitle-1">
                      Double check if the Time Slots are correctly matched up
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <!-- Dates -->
                    <v-col cols="12" md="6" lg="4">
                      <v-card>
                        <v-toolbar color="color1 color1Text--text">
                          <v-toolbar-title>Time Slots</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                          <template v-for="(t, i) in mappedSlots">
                            <v-row :key="`import${i}`" dense>
                              <v-col cols="12">
                                <v-autocomplete
                                  dense
                                  :label="t.input"
                                  :key="`team_${i}`"
                                  :items="tournament.leagueSettings.slots"
                                  color="color3"
                                  item-color="color3"
                                  v-model="t.slot"
                                  return-object
                                  :disabled="t.slot && !slotsEdit"
                                  :solo-inverted="!t.slot || slotsEdit === i"
                                  :menu-props="{
                                    closeOnContentClick: true
                                  }"
                                >
                                  <template v-slot:prepend>
                                    <v-slide-x-reverse-transition
                                      mode="out-in"
                                    >
                                      <v-icon
                                        :key="`icon-${i}-${t.slot ? 'success' : 'error'}`"
                                        v-text="t.slot ? 'fas fa-check-circle' : 'fas fa-times-circle'"
                                        :color="t.slot ? 'success' : 'error'"
                                      ></v-icon>
                                    </v-slide-x-reverse-transition>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                          </template>
                          <v-row dense>
                            <v-col class="text-end pa-0">
                              <v-btn
                                color="color3"
                                small
                                @click.stop="slotsEdit = !slotsEdit"
                                text
                              >
                                {{slotsEdit ? 'disable' : 'enable'}} edit
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0" v-if="false">
                      <v-card>
                        <v-toolbar color="color1 color1Text--text">
                          <v-toolbar-title>Match Settings</v-toolbar-title>
                        </v-toolbar>
                        <match-settings-editor
                          v-if="matchSettings"
                          :setting="matchSettings"
                          :edit="true"
                          :notime="true"
                        ></match-settings-editor>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- VERIFY COURTS -->
              <v-card color="grey lighten-4" v-if="step.type === 'verify-courts'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" md="6" lg="4" class="subtitle-1">
                      Double check if the Courts are correctly matched up
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <!-- Courts -->
                    <v-col cols="12" md="6" lg="4">
                      <v-card>
                        <v-toolbar color="color1 color1Text--text">
                          <v-toolbar-title>Courts</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                          <template v-for="(t, i) in mappedCourts">
                            <v-row :key="`import${i}`" dense>
                              <v-col cols="12">
                                <v-autocomplete
                                  dense
                                  :label="t.input"
                                  :key="`team_${i}`"
                                  :items="tournament.leagueSettings.courtNames"
                                  color="color3"
                                  item-color="color3"
                                  v-model="t.court"
                                  return-object
                                  :disabled="t.court && !courtsEdit"
                                  :solo-inverted="!t.court || courtsEdit === i"
                                  :menu-props="{
                                    closeOnContentClick: true
                                  }"
                                >
                                  <template v-slot:prepend>
                                    <v-slide-x-reverse-transition
                                      mode="out-in"
                                    >
                                      <v-icon
                                        :key="`icon-${i}-${t.court ? 'success' : 'error'}`"
                                        v-text="t.court ? 'fas fa-check-circle' : 'fas fa-times-circle'"
                                        :color="t.court ? 'success' : 'error'"
                                      ></v-icon>
                                    </v-slide-x-reverse-transition>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                          </template>
                          <v-row dense>
                            <v-col class="text-end pa-0">
                              <v-btn
                                color="color3"
                                small
                                @click.stop="courtsEdit = !courtsEdit"
                                text
                              >
                                {{courtsEdit ? 'disable' : 'enable'}} edit
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0" v-if="false">
                      <v-card>
                        <v-toolbar color="color1 color1Text--text">
                          <v-toolbar-title>Match Settings</v-toolbar-title>
                        </v-toolbar>
                        <match-settings-editor
                          v-if="matchSettings"
                          :setting="matchSettings"
                          :edit="true"
                          :notime="true"
                        ></match-settings-editor>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- VERIFY TEAMS -->
              <v-card color="grey lighten-4" v-if="step.type === 'verify-teams'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" md="6" lg="4" class="subtitle-1">
                      Double check if the teams are correctly matched up
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <!-- TEAMS -->
                    <v-col cols="12" md="6" lg="4">
                      <v-card>
                        <v-toolbar color="color1 color1Text--text">
                          <v-toolbar-title>Teams</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                          <template v-for="(t, i) in mappedTeams">
                            <v-row :key="`import${i}`" dense>
                              <v-col cols="12">
                                <v-autocomplete
                                  dense
                                  :label="t.name"
                                  :key="`team_${i}`"
                                  :items="tournament.teams"
                                  item-text="name"
                                  color="color3"
                                  item-color="color3"
                                  v-model="t.team"
                                  return-object
                                  :disabled="t.team && !teamEdit"
                                  :solo-inverted="!t.team || teamEdit === i"
                                  :menu-props="{
                                    closeOnContentClick: true
                                  }"
                                >
                                  <template v-slot:prepend>
                                    <v-slide-x-reverse-transition
                                      mode="out-in"
                                    >
                                      <v-icon
                                        :key="`icon-${i}-${t.team ? 'success' : 'error'}`"
                                        v-text="t.team ? 'fas fa-check-circle' : 'fas fa-times-circle'"
                                        :color="t.team ? 'success' : 'error'"
                                      ></v-icon>
                                    </v-slide-x-reverse-transition>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                          </template>
                          <v-row dense>
                            <v-col class="text-end pa-0">
                              <v-btn
                                color="color3"
                                small
                                @click.stop="teamEdit = !teamEdit"
                                text
                              >
                                {{teamEdit ? 'disable' : 'enable'}} edit
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0" v-if="false">
                      <v-card>
                        <v-toolbar color="color1 color1Text--text">
                          <v-toolbar-title>Match Settings</v-toolbar-title>
                        </v-toolbar>
                        <match-settings-editor
                          v-if="matchSettings"
                          :setting="matchSettings"
                          :edit="true"
                          :notime="true"
                        ></match-settings-editor>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- VERIFY SETTINGS -->
              <v-card color="grey lighten-4" v-if="step.type === 'needs-settings'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" md="6" class="subtitle-1">
                      <v-alert type="error" :value="true" prominent>
                        We couldn't find any match settings for {{step.division.name}}
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-card class="elevation-1">
                        <v-toolbar color="color1 color1Text--text">
                          <v-toolbar-title>Match Settings</v-toolbar-title>
                        </v-toolbar>
                        <match-settings-editor
                          :setting="step.setting"
                          :edit="true"
                          :notime="true"
                        ></match-settings-editor>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- CONFIRM -->
              <v-card color="grey lighten-4" v-if="step.type === 'confirm'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" class="title" v-if="parseResuls">
                      You are about to import the {{toBeImported.length}} matches from {{currentFile.name}} to {{tourney.name}}
                    </v-col>
                    <template v-for="(m, i) in mappedMatches">
                      <v-col cols="12" md="6" lg="4" xl="3" :key="`mappedMatch${i}`">
                        <v-card>
                          <v-card-text>
                            <div class="text-truncate"><b>Division:</b> {{m.division.name}}</div>
                            <div class="text-truncate"><b>Team 1:</b> {{m.home.name}}</div>
                            <div class="text-truncate"><b>Team 2:</b> {{m.away.name}}</div>
                            <div class="text-truncate"><b>Court:</b> {{m.court}}</div>
                            <div class="text-truncate"><b>Date/Time:</b> {{m.dateTime}}</div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </template>
                    <v-col cols="12">
                      <v-checkbox
                        color="color3"
                        label="I have reviewed the matches and would like to complete the upload"
                        v-model="mapConfirmed"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-divider></v-divider>
        <!-- BUTTONS -->
        <v-card-actions>
          <!-- BACK -->
          <v-fab-transition>
            <v-btn
              v-if="previousStep"
              color="color3Text color3--text"
              fab
              @click="onBackClick"
              :disabled="loading"
            ><v-icon>fas fa-caret-left</v-icon></v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              v-if="previousStep"
              color="color3"
              text
              @click="onBackClick"
              :disabled="loading"
            >Back</v-btn>
          </v-fab-transition>
          <v-spacer></v-spacer>
          <!-- NEXT -->
          <v-fab-transition>
            <v-btn
              color="color3"
              text
              :disabled="!currentStep.valid || loading"
              @click="currentStep.onClick"
            >{{currentStep.nextText || 'Next'}}</v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              color="color3 color3Text--text"
              fab
              :disabled="!currentStep.valid"
              @click="currentStep.onClick"
              :loading="loading"
            ><v-icon>{{currentStep.nextIcon || 'fas fa-caret-right'}}</v-icon></v-btn>
          </v-fab-transition>
        </v-card-actions>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import Papa from 'papaparse'
import uniq from 'lodash.uniq'
import moment from 'moment'
import { sameName } from '@/classes/HelperFunctions'
import { mapGetters } from 'vuex'
import max from 'lodash.max'
import MatchSettingsEditor from '@/components/Tournament/RoundSettings/MatchSettings.vue'
import MatchSettings from '@/classes/MatchSettings'

export default {
  props: ['tournament', 'active', 'username', 'toolbar'],
  data () {
    return {
      overComplete: false,
      window: 0,
      loading: false,
      currentFile: null,
      parseResuls: null,
      regFields: [
        { name: 'Team 1', value: '', map: ['team,1', 'home'] },
        { name: 'Team 2', value: '', map: ['team,2', 'away'] },
        { name: 'Date', value: '', map: ['date'] },
        { name: 'Time', value: '', map: ['time'] },
        { name: 'Court', value: '', map: ['court'] },
        { name: 'Division', value: '', map: ['division'] }
      ],
      hasHeader: true,
      validMap: false,
      validDivMap: false,
      mapHeaders: [
        { text: 'Map Your Columns', sortable: false, align: 'left' }
      ],
      overwrite: false,
      mapConfirmed: false,
      workingDialog: false,
      error: null,
      autoAdj: false,
      states: [],
      mappedMatches: [],
      mappedDivisions: [],
      mappedTeams: [],
      mappedSettings: [],
      mappedTimes: [],
      teamEdit: false,
      divisionEdit: false,
      mappedDates: [],
      datesEdit: false,
      mappedSlots: [],
      slotsEdit: false,
      mappedCourts: [],
      courtsEdit: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    tourney () {
      return this.tournament
    },
    steps () {
      let s = 1
      const steps = [
        {
          value: s++,
          type: 'select-file',
          key: 'select-file',
          title: 'Upload File',
          valid: !!this.currentFile,
          skip: false,
          onClick: this.onNextClick
        },
        {
          value: s++,
          type: 'verify-data',
          key: 'verify-data',
          title: 'Verify Data',
          valid: true,
          skip: false,
          onClick: this.onNextClick
        },
        {
          value: s++,
          type: 'column-mapping',
          key: 'column-mapping',
          title: 'Map The Columns',
          valid: this.regFields.filter(f => !f.value).length === 0,
          skip: false,
          onClick: () => {
            this.mapSlots()
            this.mapCourts()
            this.mapDivisions()
            if (this.mappedDivisions.filter(f => !f.division).length === 0) {
              this.mapDates()
            }
            this.onNextClick()
          }
        },
        {
          value: s++,
          type: 'verify-slots',
          key: 'verify-slots',
          title: 'Verify Time Slots',
          valid: this.mappedSlots.filter(f => !f.slot).length === 0,
          skip: this.mappedSlots.length > 0 && this.mappedSlots.filter(f => !f.slot).length === 0,
          onClick: this.onNextClick
        },
        {
          // beforeEnter: this.mapCourts,
          value: s++,
          type: 'verify-courts',
          key: 'verify-courts',
          title: 'Verify Courts',
          valid: this.mappedCourts.filter(f => !f.court).length === 0,
          skip: this.mappedCourts.length > 0 && this.mappedCourts.filter(f => !f.court).length === 0,
          onClick: this.onNextClick
        },
        {
          // beforeEnter: this.mapDivisions,
          value: s++,
          type: 'verify-divisions',
          key: 'verify-divisions',
          title: 'Verify Divisions',
          valid: this.mappedDivisions.filter(f => !f.division).length === 0,
          skip: this.mappedDivisions.length > 0 && this.mappedDivisions.filter(f => !f.division).length === 0,
          onClick: () => {
            console.log('here')
            this.mapDates()
            this.onNextClick()
          }
        },
        {
          // beforeEnter: this.mapDates,
          value: s++,
          type: 'verify-dates',
          key: 'verify-dates',
          title: 'Verify Dates',
          valid: this.mappedDates.filter(f => !f.round).length === 0,
          skip: this.mappedDates.length > 0 && this.mappedDates.filter(f => !f.round).length === 0,
          onClick: this.onNextClick
        },
        {
          beforeEnter: this.mapTeams,
          value: s++,
          type: 'verify-teams',
          key: 'verify-teams',
          title: 'Verify Teams',
          valid: this.mappedTeams.filter(f => !f.team).length === 0,
          skip: false,
          onClick: this.onNextClick
        },
        ...this.needsSettings.map(m => {
          return {
            value: s++,
            type: 'needs-settings',
            key: 'needs-settings',
            title: 'Match Settings',
            valid: true,
            setting: new MatchSettings({
              poolsOf: 0,
              matchTypeId: 1
            }, true, true),
            division: m.division,
            skip: false,
            onClick: this.applySettings
          }
        }),
        {
          beforeEnter: this.mapMatches,
          value: s++,
          type: 'confirm',
          key: 'confirm',
          title: 'Confirm & Upload',
          valid: this.mapConfirmed,
          skip: false,
          complete: true,
          nextText: 'Go',
          nextIcon: 'fas fa-upload',
          onClick: this.onCompleteClick
        }
      ]

      return steps
    },
    currentI () {
      return this.steps.findIndex(f => f.value === this.window)
    },
    currentStep () {
      return this.steps.find(f => f.value === this.window)
    },
    step1 () {
      return this.steps.find(f => f.value > 0 && !f.skip)
    },
    nextStep () {
      return this.steps.find(f => f.value > this.window && !f.skip)
    },
    previousStep () {
      var x = this.steps.filter(f => f.value < this.window && !f.skip)
      return x && x[x.length - 1]
    },
    nextBtn () {
      return this.currentStep ? {
        show: this.nextStep || this.currentStep.complete,
        disabled: !this.currentStep.valid,
        onClick: this.currentStep.complete ? this.onCompleteClick : this.onNextClick,
        text: this.currentStep.nextText || 'Next',
        icon: this.currentStep.nextIcon || 'fas fa-caret-right',
        loading: this.loading
      } : null
    },
    columnOptions () {
      const options = []
      if (this.parseResuls) {
        for (const prop in this.parseResuls.data[0]) {
          options.push({ text: this.hasHeader ? `${prop}` : `Col ${prop}`, value: prop })
        }
      }
      return options
    },
    rows () {
      return this.parseResuls && this.parseResuls.data
    },
    headers () {
      const head = []
      if (this.parseResuls) {
        for (const prop in this.parseResuls.data[0]) {
          head.push({
            text: this.hasHeader ? `${prop}` : `Col ${prop}`,
            value: `${prop}`,
            sortable: false,
            align: 'left'
          })
        }
      }
      return head
    },
    unMappedFields () {
      return this.regFields.filter(f => !f.value)
    },
    mappedFields () {
      var result = {}
      for (const f of this.regFields) {
        result[f.name] = f.value
      }
      return result
    },
    divisionOptions () {
      if (!this.tourney) return []
      var options = [...this.tourney.publicDivisions]
      options.push({ name: 'Do not import', id: 0 })
      return options
    },
    toBeImported () {
      return (this.parseResuls && this.parseResuls.data) || []
    },
    dto () {
      return this.mappedMatches.map(m => {
        return {
          poolId: m.pool.id,
          number: m.pool.matches.length ? max(m.pool.matches.map(m => m.number)) + 1 : 1,
          court: m.court,
          homeTeamIds: [m.home.id],
          awayTeamIds: [m.away.id],
          startTime: m.startTime,
          isMatch: m.setting.isMatch,
          settings: m.setting
        }
      })
    },
    noPool () {
      return this.mappedMatches.filter(f => !f.pool)
    },
    needsSettings () {
      return this.mappedMatches.filter(f => !f.setting)
    }
  },
  methods: {
    applySettings () {
      const s = this.currentStep.setting
      const d = this.currentStep.division.id
      this.needsSettings.filter(f => f.division.id === d).forEach(f => {
        f.setting = s.dto
      })
      const step = this.needsSettings.length === 0 ? 'confirm' : null
      this.onNextClick(step)
    },
    onBackClick () {
      if (!this.previousStep) return
      this.window = this.previousStep.value
    },
    onNextClick (step) {
      this.$refs && this.$refs.form && this.$refs.form[0].validate()
      const ns = step === 'confirm' ? this.steps.find(f => f.key === 'confirm') : this.nextStep
      if (!ns) return
      this.teamEdit = false
      if (ns.beforeEnter && step !== 'confirm') ns.beforeEnter()
      const n = ns.value
      this.window = n
    },
    onCompleteClick () {
      this.upload()
    },
    onPickFile () {
      this.$refs.fileInput[0].click()
    },
    onFilePicked (event) {
      const file = event.target.files[0]
      if (!file) return
      if (file.name.lastIndexOf('.csv') <= 0) {
        alert('Please select a CSV file')
        return
      }
      this.loading = true
      this.currentFile = file
      this.parseFile()
      this.onNextClick()
      this.loading = false
    },
    parseFile () {
      Papa.parse(this.currentFile, {
        header: this.hasHeader,
        complete: (results, file) => {
          this.currentFile = file

          for (const e of results.errors) {
            results.data.splice(e.row, 1)
          }
          const checkProperties = (obj) => {
            for (var key in obj) {
              if (obj[key] !== null && obj[key] !== '') return true
            }
            return false
          }

          results.data = results.data.filter((r) => checkProperties(r))

          this.parseResuls = results
          this.tryMap()
        }
      })
    },
    tryMap () {
      const dc = this.columnOptions.filter(f => f.value).filter(f => f.value.toLowerCase().includes('division'))
      if (dc.length > 0) this.divisionColumn = dc[0].value
      const fields = this.regFields.filter((f) => { return f.map && f.map.length > 0 })

      for (const field of fields) { // each field that has a map array
        for (const m of field.map) {
          let options = this.columnOptions.filter(f => f.value) // column headers
          const filters = m.split(',')
          for (const filter of filters) {
            options = options.filter(o => o.value.toLowerCase().includes(filter.toLowerCase()))
          }
          if (field.not) {
            for (const x of field.not) {
              options = options.filter(o => !o.value.toLowerCase().includes(x.toLowerCase()))
            }
          }

          if (options.length > 0) {
            field.value = options[0].value
            break
          }
        }
      }
    },
    clear () {
      this.currentFile = null
      this.parseResuls = null
      this.$refs.fileInput[0].value = null
      this.window = 0
      this.selector = this.finishThem = this.overComplete = false
      this.window = this.nextStep.value
    },
    getTeam (name, division, useSeed) {
      const d = division && division.division
      if (useSeed && d) {
        return d.teams.find(f => f.seed === +name)
      }
      const dTeam = d && d.activeTeams.find(f => f.name.trim().toLowerCase() === name.trim().toLowerCase())
      return dTeam || this.tournament.activeTeams.find(f => f.name.trim().toLowerCase() === name.trim().toLowerCase())
    },
    ensureMappedTeam (name, division, useSeed, team, i, h) {
      const n = useSeed ? `${division.name} - ${name}` : name
      const x = this.mappedTeams.find(f => f.name === n && f.division.id === division.id && f.useSeed === useSeed) || {
        division: division,
        useSeed: useSeed,
        team: team,
        is: []
      }
      if (!x.name) this.mappedTeams.push(x)
      x.name = n
      x.is.push(`${i}${h}`)
    },
    mapDivisions () {
      this.mappedDivisions = []
      const names = uniq([...this.toBeImported.map(m => m[this.mappedFields.Division])])
      names.forEach(name => {
        const dName = name.trim().toLowerCase()
        const d = this.tournament.divisions.find(f => f.name.trim().toLowerCase() === dName.trim().toLowerCase())
        this.mappedDivisions.push({ name: dName, division: d })
      })
    },
    mapSlots () {
      this.mappedSlots = []
      const times = uniq([...this.toBeImported.map(m => m[this.mappedFields.Time])])
      times.forEach(time => {
        const slots = this.tournament.leagueSettings.slots
        const s = slots.find(f => f === time)
        this.mappedSlots.push({ input: time, slot: s })
      })
    },
    mapCourts () {
      this.mappedCourts = []
      const courts = uniq([...this.toBeImported.map(m => m[this.mappedFields.Court])])
      courts.forEach(court => {
        const courtNames = this.tournament.leagueSettings.courtNames
        const c = courtNames.find(f => f === court)
        this.mappedCourts.push({ input: court, court: c })
      })
    },
    mapDates () {
      this.mappedDates = []
      const map = this.mappedFields
      this.toBeImported.forEach((r, i) => {
        const dName = r[this.mappedFields.Division].trim().toLowerCase()
        const d = this.mappedDivisions.find(f => f.name.trim().toLowerCase() === dName)
        const dateValue = r[map.Date]
        const timeValue = r[map.Time]
        const dateFormat = dateValue.includes('/') ? 'MM/DD/YYYY' : false
        const timeFormat = timeValue.toUpperCase().includes('M') ? timeValue.toUpperCase().includes(' ') ? 'h:mm A' : 'h:mmA' : 'HH:mm'
        const date = dateFormat ? moment(dateValue, dateFormat) : moment(dateValue)
        date.utc(true)
        const time = moment(timeValue, timeFormat)
        date.add(time.get('hour'), 'h').add(time.get('minute'), 'm')
        const round = d.division.days.find(f => f.date.isSame(date, 'day'))
        this.mappedDates.push({ name: date.format('MM/DD/YYYY'), division: d.division, round: round })
      })
    },
    mapTeams () {
      const names = uniq([...this.toBeImported.map(m => m[this.mappedFields['Team 1']]), ...this.toBeImported.map(m => m[this.mappedFields['Team 2']])])
      const useSeed = !names.find(f => isNaN(f))
      this.toBeImported.forEach((r, i) => {
        const h = r[this.mappedFields['Team 1']].trim().toLowerCase()
        const a = r[this.mappedFields['Team 2']].trim().toLowerCase()
        const dName = r[this.mappedFields.Division].trim().toLowerCase()
        const d = this.mappedDivisions.find(f => f.name.trim().toLowerCase() === dName)
        const hObj = this.getTeam(h, d, useSeed)
        this.ensureMappedTeam(h, d, useSeed, hObj, i, 'h')
        const aObj = this.getTeam(a, d, useSeed)
        this.ensureMappedTeam(a, d, useSeed, aObj, i, 'a')
      })
    },
    mapTeamsOg () {
      const names = uniq([...this.toBeImported.map(m => m[this.mappedFields['Team 1']]), ...this.toBeImported.map(m => m[this.mappedFields['Team 2']])])
      const x = this.mappedTeams.map(m => m.name)
      const removeMe = x.filter(f => !names.includes(f))
      const addMe = names.filter(f => !x.includes(f))
      removeMe.forEach(f => {
        this.mappedTeams = this.mapMatches.filter(f => f.name !== f)
      })
      addMe.forEach(name => {
        this.mappedTeams.push({
          name: name,
          team: this.tournament.teams.find(f => sameName(f.name, name))
        })
      })
    },
    getMappedTeam (i, h) {
      const team = this.mappedTeams.find(f => f.is.includes(`${i}${h}`))
      return team && team
    },
    mapMatches () {
      this.mappedMatches = []
      if (!this.mappedFields || !this.parseResuls || !this.parseResuls.data) {
        return
      }
      const timeline = this.tournament.leagueTimeline
      const map = this.mappedFields
      this.toBeImported.forEach((record, i) => {
        const homeObj = this.getMappedTeam(i, 'h')
        const home = homeObj.team
        const homeDivision = homeObj.division
        const awayObj = this.getMappedTeam(i, 'a')
        const away = awayObj.team
        const awayDivision = awayObj.division

        const round = this.mappedDates[i].round

        const date = round.date
        const mappedSlot = this.mappedSlots.find(f => f.input === record[map.Time])
        const timeValue = mappedSlot.slot
        const timeFormat = timeValue.toUpperCase().includes('M') ? timeValue.toUpperCase().includes(' ') ? 'h:mm A' : 'h:mmA' : 'HH:mm'
        date.utc(true)
        const time = moment(timeValue, timeFormat)
        date.add(time.get('hour'), 'h').add(time.get('minute'), 'm')
        const mappedCourt = this.mappedCourts.find(c => c.input === record[map.Court])
        const court = mappedCourt.court

        const unix = date.unix()
        const timelineSlots = round && timeline.filter(f => f.unix === unix && f.court === court)
        const slot = timelineSlots && timelineSlots.find(f => !f.m)
        const taken = timelineSlots && timelineSlots.find(f => f.m)
        this.mappedMatches.push({
          homeName: home.name,
          home: home,
          awayName: away.name,
          away: away,
          startTime: date.format('YYYY-MM-DD[T]HH:mm'),
          court: court,
          dateTime: date.format('llll'),
          pool: round.pools[0],
          setting: this.getSettings(round, homeDivision),
          division: homeDivision,
          errors: {
            division: homeDivision && awayDivision && homeDivision.id === awayDivision.id ? false : 'Teams are from different Divisions',
            slot: !slot ? 'Could not find the give slot' : slot && !taken ? false : slot && taken ? 'There is already a match in this slot' : '???'
          }
        })
      })
    },
    getSettings (round, division, trickle) {
      var s = round && round.flight && round.flight.settings.length > 0 && round.flight.settings[0]
      if (s) {
        const a = s.dto
        if (trickle) {
          a.id = 0
        }
        return a
      }
      var n = round.number
      if (n === 1) return null
      return this.getSettings(division.division.rounds.find(f => f.number === n - 1), division, true)
    },
    upload () {
      this.loading = true
      this.$VBL.post.matches(this.dto, true)
        .then(r => {
          this.$emit('complete')
        })
        .catch((e) => {
          this.error = e.response.data
          console.log(e)
        })
        .finally(() => { this.loading = false })
    },
    complete () {
      this.$emit('complete')
      this.workingDialog = false
    }
  },
  mounted () {
    this.window = this.step1.value
  },
  watch: {
    active: function (n, o) {
      console.log('active')
      if (o && !n) this.clear()
      this.window = this.step1.value
    },
    hasHeader () {
      this.parseFile()
    },
    workingDialog: function (val) {
      if (!val) {
        this.clear()
      }
    },
    loading: function (v) {
      this.$emit('loading-change', v)
    }
  },
  components: {
    MatchSettingsEditor
  }
}
</script>

<style>

</style>
